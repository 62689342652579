import { createEnv } from '@t3-oss/env-core';
import { z } from 'zod';

export const env = createEnv({
  clientPrefix: 'VITE_',
  client: {
    VITE_APP_URL: z.string().url(),
    VITE_API_URL: z.string().url(),
    VITE_SENTRY_DSN: z.string().url(),
    VITE_SENTRY_PROJECT: z.string(),
    VITE_SENTRY_ORG: z.string(),
    VITE_SENTRY_ENV: z.enum(['production', 'development']),
    VITE_GTM_ID: z.string(),
  },
  runtimeEnv: import.meta.env,
  emptyStringAsUndefined: true,
});
